import { createEffect } from "solid-js";
import { isServer } from "solid-js/web";

export function GoogleAnalytics() {
  createEffect(() => {
    if (isServer) return;

    const isOffersSubdomain =
      window.location.hostname === "offers.myhubble.money";
    const isRaisoniPortal =
      window.location.hostname === "privilege.raisoni.net";

    if (isOffersSubdomain) {
      const scriptGtag = document.createElement("script");
      scriptGtag.async = true;
      scriptGtag.src =
        "https://www.googletagmanager.com/gtag/js?id=G-B9ZDLYVCH6";
      document.head.appendChild(scriptGtag);

      const inlineScript = document.createElement("script");
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-B9ZDLYVCH6');
      `;
      document.head.appendChild(inlineScript);
    } else if (isRaisoniPortal) {
      const script = document.createElement("script");
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WVL953L3');`;
      document.head.appendChild(script);

      // Add noscript iframe
      const noscriptContainer = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WVL953L3";
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      noscriptContainer.appendChild(iframe);
      document.body.appendChild(noscriptContainer);
    }
  });

  return null;
}
